import { Accordion, AccordionDetails, AccordionSummary, Button, Container, Divider, Typography } from '@mui/material'
import Link from 'next/link'
import styles from './CategoriesMobile.module.css'
import { getMarketPlaceCategories } from '@/lib/supabaseApi'
import { Category, MarketplaceCategoriesWithChilds } from 'types/supabase.types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouter } from 'next/router'
import { DEPARTMENT_NAME } from '@/lib/constants'

type CategoriesMobileProps = {
  setMenuIsExpanded: Dispatch<SetStateAction<Boolean>>
  setActiveCategory: Dispatch<SetStateAction<String>>
}

export default function CategoriesMobile({ setMenuIsExpanded, setActiveCategory }: CategoriesMobileProps) {
  const [categories, setCategories] = useState<MarketplaceCategoriesWithChilds[] | null>()
  const [categoriesDepartment, setCategoriesDepartment] = useState<any[] | null>()
  const { t } = useTranslation()
  const router = useRouter()

  useEffect(() => {
    getMarketPlaceCategories().then((categories) => {
      if (categories) {
        setCategories(categories)

        const depName = categories.find((c) => c.name.toLowerCase() === DEPARTMENT_NAME.toLowerCase())
        let categoriesChild: any = []
        if (depName) {
          categoriesChild = (categories.find((c) => c.id === depName.id)?.childCategories)
          setCategoriesDepartment(categoriesChild)
        }
      }
    })
  }, [])

  const setActiveMenu = (e: any) => {
    if (!e.target.offsetParent.getElementsByTagName('p')[0].classList.contains('active')) {
      e.target.offsetParent.getElementsByTagName('p')[0].style.fontWeight = 700
      e.target.offsetParent.getElementsByTagName('p')[0].classList.add('active')
    } else {
      e.target.offsetParent.getElementsByTagName('p')[0].style.fontWeight = 400
      e.target.offsetParent.getElementsByTagName('p')[0].classList.remove('active')
    }
  }

  // TODO: Make scroll with 'categories__mobile__father' class
  return (
    <div className={styles.categories__mobile__father}>
      {categories?.map((category: MarketplaceCategoriesWithChilds, i: number) => {
        if (category.name.toLowerCase() !== DEPARTMENT_NAME.toLowerCase()) {
          return (
            <Accordion key={i} className={styles.menu__accordion} onClick={setActiveMenu}>
              <div className={styles.menu__accordion__summary}>
                <Typography className={styles.menu__mobile__accordion__details}
                  {...(!category.childCategories.length && {
                    onClick: () => {
                      if (category.name.toLowerCase().includes('amazon')) {
                        const categoryAmazon = (category as any)
                        // router.push(categoryAmazon.url)
                        window.location.href = categoryAmazon.url
                      } else {
                        router.push(`/marketplaces/${category.id}?${category.name}`)
                      }
                      setMenuIsExpanded(false)
                      setActiveCategory(category.name.toUpperCase())
                    }
                  })}
                >
                  <b>{category.name.toUpperCase()}</b>
                </Typography>
              </div>
              {category.childCategories.map((child: Category, i: number) => {
                return (
                  <Link key={i} href={`/marketplaces/${child.id}?${child.name}`}>
                    <AccordionDetails className={styles.menu__accordion__details} onClick={() => {
                      setMenuIsExpanded(false)
                      setActiveCategory(child.name.toUpperCase())
                    }}>
                      <Typography>{child.name.toUpperCase()}</Typography>
                    </AccordionDetails>
                  </Link>
                )
              })}
            </Accordion>
          )
        }
      })}

      <Container sx={{ pl: 2, mt: 0 }}>

      <Link href={'/marketplaces/all'}>
        <Typography
          sx={{ fontWeight: 'bold', textTransform: 'uppercase', cursor: 'pointer', marginTop: '3%' }}
          onClick={() => {
            setMenuIsExpanded(false)
            setActiveCategory('')
          }}
        >
          {t('button.all_marketplaces')}
        </Typography>
      </Link>

      <Divider style={{ margin: '2rem 0rem' }} />

      {categoriesDepartment?.map((category: any, i: number) => {
        return (
          <Link key={i} href={`/marketplaces/${category.id}?${category.name}`}>
            <Typography style={{ marginTop: '0.9rem' }}
              onClick={() => {
                router.push(`/marketplaces/${category.id}?${category.name}`)
                setMenuIsExpanded(false)
                setActiveCategory('')
              }}>
                {category.name.toUpperCase()}
            </Typography>
          </Link>
        )
      })}

      <Link href={'/custom-order'}>
        <Button style={{ marginBottom: 50 }} sx={{ height: 30, p: 2, mt: 2 }} onClick={() => setMenuIsExpanded(false)}>
          <Typography style={{ fontWeight: 'bold' }}>
            {t('button.custom_order')}
          </Typography>
        </Button>
      </Link>

      </Container>
    </div >
  )
}
